$m: 768px;
$l: 1200px;

@mixin mq($breakpoint) {
  @if $breakpoint == m {
    @media (min-width: $m) {
      @content;
    }
  } @else if $breakpoint == l {
    @media (min-width: $l) {
      @content;
    }
  } @else if $breakpoint == m-only {
    @media (max-width: ($m - 0.02)) {
      @content;
    }
  }@else if $breakpoint == l-only {
    @media (max-width: ($l - 0.02)) {
      @content;
    }
  }
}