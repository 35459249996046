@import './mixin';
.primaryBtn.euiButton {
    color: var(--primary-button-text);

    &[class*='isDisabled-fill-disabled'] {
        background-color: var(--primary-color);
    }
}

.borderedButton.euiButton {
    border: 1px solid currentColor;
    background-color: var(--primary-background);
}

 .euiCheckbox .euiCheckbox__input+.euiCheckbox__square {
     padding: 11px;
     top: 0;
 }

 .euiCheckbox .euiCheckbox__input:checked+.euiCheckbox__square {
     border-color: var(--primary-color);
     background-color: var(--primary-color);
 }

.euiTableRowCell {
    border-top: none !important;
    border-block-start: none !important;
    border-color: var(--border-color) !important;
}

.table {
    th {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        color: var(--subdued-color);
        height: 50px;
    }

    tr {
        box-shadow: none;
    }

    .euiTable thead {
        height: 50px;
        position: sticky;
        top: 0;
        z-index: 10;
        background: var(--table-header-background);
    }

    .euiTableCellContent {
        padding-right: 16px;
    }

    &>.euiFlexGroup:last-child {
        padding-block: 6px;
    }
    .euiCheckbox .euiCheckbox__input+.euiCheckbox__square {
        padding: 8px;
        top: -1px;
    }
    .euiButtonEmpty__content {
        padding-inline: 0;
    }
}

.tableWithCheckbox {
    tr th:first-child,
    td:first-child {
      .euiTableCellContent {
        padding-right: 0px;
      }
    }
}

.tableOverflow {
    overflow: auto;
    height: calc(100vh - 270px);
    min-height: 200px;
    @include mq(l) {
        height: calc(100vh - 167px);
    }
}

.tableEmptyData {
    min-height: calc(100vh - 220px);
    @include mq(l) {
        min-height: calc(100vh - 120px);
    }
}

.borderedItems .euiContextMenuItem:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.organizationSelect.euiSuperSelectControl {
    width: 100%;
    text-align: center;
}


.organizationSelectItem {
    .euiContextMenuItem__text {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.pageSection[class*='euiPageSection']  {
    padding-inline: 20px;
    height: 100%;
    overflow: auto;
    @include mq(m) {
        padding-inline: 32px;
    }
}

.mobileHidden {
    @include mq(m-only) {
        display: none !important;
    }
}

.messagesWrapper {
    padding: 12px;
    border-top: 1px solid var(--border-color);
    @include mq(m) {
    padding: 24px;
    }
}

.whiteSpaceText {
    white-space: pre-line;
    @include mq(m) {
        white-space: nowrap;
    }
}